import { handleResponse } from "../utils/utils";
import { companyService, timeTypesService } from "services";

export const authService = {
	login,
	register,
	getToken,
	getCurrent,
	signup,
	logout,
	cleanStorage,
	setToken,
	setUser,
};

try {
	authService.currentUser = JSON.parse(localStorage.getItem("user"));
	authService.token = JSON.parse(localStorage.getItem("token"));
} catch (e) { }

// get token from user
async function login(username, password) {
	const token = await getToken(username, password);

	if (token.ok) {

		// limpiar avatarsDB en indexedDB, que es el que almacena los avatares
		await clearIndexedDB();

		setToken(token);

		let user = await getCurrent(token);
		// console.log(user);
		if (user === undefined) {
			return {};
		} else if (user.ok) {
			await setUser(user);

			return {
				ok: true,
				user,
				token,
			};
		} else {
			return { errors: user.errors, ok: false };
		}
	} else {
		return { errors: token.errors, ok: false };
	}
}

// new user (and company) registration + login
async function register(user) {
	const newUser = await signup(user);
	// console.log(newUser);

	if (newUser.ok) {
		const token = await getToken(user.email, user.password);
		// console.log(token);

		if (token.ok) {
			await setToken(token);
			await setUser(newUser);

			return {
				ok: true,
				user: newUser,
				token,
			};
		} else {
			return { errors: token.errors, ok: false };
		}
	} else {
		return { errors: newUser.errors, ok: false };
	}
}

// get token from user
function getToken(username, password) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${global.API_KEY}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			grant_type: "password",
			username,
			password,
		}),
	};

	return fetch(`${global.BASE_API}auth/token/`, requestOptions).then(
		handleResponse
	);
}

// gets current user
function getCurrent() {
	const requestOptions = {
		method: "GET",
		// mode: "cors",
		headers: {
			// "Access-Control-Allow-Headers": "*",
			// "Access-Control-Allow-Origin": "*",
			Authorization: `Bearer ${authService.token.access_token}`,
			"Content-Type": "application/json",
		},
	};

	return fetch(`${global.BASE_API}employees/current/`, requestOptions).then(
		handleResponse
	);
}

// new employee signup
function signup(user) {
	const requestOptions = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${global.API_KEY}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(user),
	};

	return fetch(`${global.BASE_API}apps/signup/`, requestOptions).then(
		handleResponse
	);
}

// remove token & user from local storage to log user out
function logout() {
	// console.log("logout");
	cleanStorage();
	window.location.reload(true);
}

// Función para limpiar IndexedDB
function clearIndexedDB() {
	return new Promise((resolve, reject) => {
		const request = indexedDB.deleteDatabase('avatarsDB');

		request.onsuccess = () => {
			resolve();
		};

		request.onerror = (event) => {
			reject(event.target.errorCode);
		};
	});
}

async function cleanStorage() {
	// console.log("cleanStorage");
	localStorage.removeItem("token");
	localStorage.removeItem("user");
	authService.token = null;
	authService.currentUser = null;

	// limpiar avatarsDB en indexedDB, que es el que almacena los avatares
	await clearIndexedDB();
}

function setToken(token) {
	localStorage.setItem("token", JSON.stringify(token));
	authService.token = token;
}

async function setUser(user) {
	// console.log("setUser");
	var subscription = await companyService.getSubscriptions(user.company.id);
	// console.log(subscription);
	if (subscription.ok) {
		user.subscription = subscription[0];
	}

	var employee_with_time_types = await timeTypesService.getContractTimeTypes({ employee_id: user.id });
	// console.log(employee_with_time_types);
	user.employee_with_time_types = employee_with_time_types.ok ? employee_with_time_types : [];


	localStorage.setItem("user", JSON.stringify(user));
	authService.currentUser = user;
}
