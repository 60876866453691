import { authService } from "services";
import moment from "moment";

export function isLogin() {
	return authService.currentUser && authService.token;
}

export function isAdmin() {
	// return authService.currentUser && authService.currentUser.role === "ADMIN";
	return (
		authService.currentUser && authService.currentUser.roles.includes("ADMIN")
	);
}

export function isSupervisor() {
	return (
		authService.currentUser &&
		authService.currentUser.roles.includes("SUPERVISOR")
	);
}

export function isTrial() {
	const user = authService.currentUser;
	return (
		isAdmin() &&
		user.subscription &&
		user.subscription.subscription_type &&
		user.subscription.subscription_type.name == "Trial"
		// user.subscription.subscription_type.name == "Pro"
	);
}

export function getSubscription() {
	const user = authService.currentUser;
	return user.subscription;
}

export function getTrialDays() {
	const user = authService.currentUser;
	const now = new Date();
	const startTime = moment.utc(user.subscription.start_day).local();
	const totalDays = startTime.diff(moment(now), "days") + 15;
	return totalDays;
}

// handle fetch responses
// export function handleResponse(response) {
// 	// console.log(response);
// 	//console.log(response.headers);

// 	if (response.redirected) {
// 		window.location.href = response.url;
// 		return {};
// 	}
// 	return response.text().then((text) => {
// 		var server_error = "";
// 		if (!response.ok) {
// 			if (response.status === 401) {
// 				// auto logout if 401 response returned from api
// 				authService.logout();
// 				window.location.reload(true);
// 			}
// 			// else if (response.status === 404) {
// 			// 	const errors = [];
// 			// 	console.log(text);
// 			// 	errors.push({ description: "No encontrado" });
// 			// 	server_error = { errors }
// 			// }
// 			else if (response.status === 403) {
// 				const errors = [];
// 				errors.push({ description: "Error de permisos" });
// 				server_error = { errors }
// 			}
// 			else if (response.status === 500) {
// 				const errors = [];
// 				errors.push({ description: "Ha habido un error de servidor" });
// 				server_error = { errors }
// 			}
// 			// if (response.status === 404) {
// 			//     console.log("not found");
// 			//     window.location.href= "/404";

// 			//     return "No encontrado"
// 			// }
// 		}
// 		var data = text && JSON.parse(text);
// 		console.log(data);

// 		if (server_error !== "") {
// 			data = server_error;
// 		}

// 		if (data !== undefined && data !== "") {
// 			data.ok = response.ok;
// 		}

// 		console.log(data);
// 		return data;
// 	});
// }

export function handleResponse(response) {
	if (response.redirected) {
		window.location.href = response.url;
		return {};
	}

	return response.text().then((text) => {
		let data;
		try {
			data = text ? JSON.parse(text) : {};
		} catch (error) {
			console.error("Error parsing response JSON:", error);
			data = { errors: [{ description: "Error procesando la respuesta del servidor" }] };
		}

		if (!response.ok) {
			// Manejo específico de errores basado en el código de estado
			let errors = [];
			switch (response.status) {
				case 401:
					authService.logout();
					window.location.reload(true);
					break;
				case 403:
					errors.push({ description: "Error de permisos" });
					break;
				case 500:
					errors.push({ description: "Ha habido un error de servidor" });
					break;
				// Añadir más casos según sea necesario
			}
			if (errors.length > 0) {
				data = { errors };
			}
		}

		data.ok = response.ok;
		// console.log(data);
		return data;
	});
}


export function handleImgResponse(response) {
	// console.log(response);
	return response.text().then((text) => {
		if (response.status === 404) {
			return "";
		}

		// console.log(data);
		return text;
	});
}

export function handleBlobResponse(response) {
	return response.blob().then((blob) => {
		if (response.status === 404) {
			return "";
		}

		return blob;
	});
}

// error formating in forms
export function errorFormating(response) {
	//console.log(response);
	var errors = {};
	if (response.errors !== undefined && Object.keys(response.errors).length > 0) {
		// console.log(response.errors)

		if (Object.keys(response.errors).length === 1 && response.errors[0].code === undefined) {
			errors.general = response.errors[0].description;
		} else {
			response.errors.forEach((error) => {
				if (error.fields.length > 0) {
					error.fields.forEach((field) => {
						errors[field] = error.description;

						if (error.value) {
							errors[field + "_value"] = error.value;
						}
					});
				} else {
					errors[error.code] = error.description;
				}
			});
		}

	}
	//console.log(errors);
	return errors;
}

export function renderErrorList(response) {
	return (
		<div className="error-box">
			{response.errors && response.errors.length > 0 && (
				<>
					<p>Se han encontrado los siguientes errores:</p>
					<ul className="list-error">
						{response.errors.map((error, index) => (
							<li key={index}>
								{error.description} {error.value && `(${error.value})`}
							</li>
						))}
					</ul>
				</>
			)}
		</div>
	);
}



// geolocation + IP to add to requests

export function getGeoIP() {
	var GeoIPObj = { lat: 0, lon: 0, ip: "1.2.3.4" };

	// es necessita $$$ per fer servir https a full:
	// fetch('http://ip-api.com/json')
	//   .then(function(response) {
	//     return response.json();
	//   })
	//   .then(function(result) {
	//     // console.log(result);
	//     GeoIPObj = result;
	//     setGeoIP(GeoIPObj);
	//   });
	let params = {
		method: "GET",
		mode: "cors", // no-cors, *cors, same-origin
		credentials: "same-origin", // include, *same-origin, omit
		headers: {
			"Content-Type": "application/json",
		},
	};

	fetch("https://api.ipify.org/?format=json", params)
		.then(function (response) {
			return response.json();
		})
		.then(function (result) {
			// console.log(result);
			// GeoIPObj = result;
			GeoIPObj.ip = result.ip;
			setGeoIP(GeoIPObj);
		});

	// if geolocation is enabled lat & lon coords are more accurated
	if ("geolocation" in navigator) {
		navigator.geolocation.getCurrentPosition(function (position) {
			// console.log(position.coords.latitude, position.coords.longitude);
			GeoIPObj.lat = position.coords.latitude;
			GeoIPObj.lon = position.coords.longitude;
			// console.log(GeoIPObj);
			setGeoIP(GeoIPObj);
		});
	}
}

// expected output: X-Geo-IP: city:Rome, country:IT, lat:44.2134, lon:12.241, ip:1.2.3.4
export function setGeoIP(GeoIPObj) {
	// global.GetIPHeader = `city:${GeoIPObj.city}, country:${GeoIPObj.countryCode}, lat:${GeoIPObj.lat}, lon:${GeoIPObj.lon}, ip:${GeoIPObj.query}`;
	global.GetIPHeader = `lat:${GeoIPObj.lat}, lon:${GeoIPObj.lon}, ip:${GeoIPObj.ip}`;
	// console.log(global.GetIPHeader);
}


export function chunkArray(array, size) {
	const result = []
	for (let i = 0; i < array.length; i += size) {
		result.push(array.slice(i, i + size))
	}
	return result
}

// color de contraste para textos
export function getContrastColor(hexcolor, defaultColor) {
	const darkColor = defaultColor ? defaultColor : "black";
	if (!hexcolor) {
		return darkColor;
	}
	hexcolor = hexcolor.replace("#", "");
	var r = parseInt(hexcolor.substr(0, 2), 16);
	var g = parseInt(hexcolor.substr(2, 2), 16);
	var b = parseInt(hexcolor.substr(4, 2), 16);
	var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

	return (yiq >= 128) ? darkColor : 'white';
}
