import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import "./Sidebar.scss";
import { NavLink } from "react-router-dom";
import {
	FiHome,
	FiTrello,
	FiClock,
	FiUser,
	FiUsers,
	FiExternalLink,
	FiFolder,
	FiCalendar,
	FiRefreshCcw,
	FiSliders,
	FiX,
	FiMenu,
	FiHelpCircle,
	FiChevronRight,
} from "react-icons/fi";
import { Popover, OverlayTrigger, Tooltip } from "react-bootstrap";
import { isAdmin, isSupervisor } from "utils/utils";
import { authService } from "services";
import Avatar from "components/Avatar";
import logo from "assets/logo.svg";
import iso from "assets/isotype.svg";

const Sidebar = (props) => {
	// console.log(props);
	const { user, isTrialEnded } = props;

	const handleLogout = () => {
		authService.logout({});
	};

	const popover = <Popover id="popoverSidebar" className="popover-sidebar">
		<ul className="sidebar-bottom-menu">
			{!isTrialEnded &&
				(<li className="option-profile">
					<Link className="sidebar-menu-item" to="/profile">
						Perfil
					</Link>
				</li>)}
			{!isTrialEnded && isAdmin() && (
				<>
					<li className="option-company">
						<Link className="sidebar-menu-item" to="/my-company">
							Mi empresa
						</Link>
					</li>
					<li className="option-subscription">
						<Link className="sidebar-menu-item" to="/my-subscription">
							Mi suscripción
						</Link>
					</li>
				</>
			)}
			<li className="option-logout">
				<button
					className="sidebar-menu-item button-link"
					onClick={handleLogout}
				>
					Cerrar Sesión
				</button>
			</li>
		</ul>
	</Popover>;

	return (
		<section className={`sidebar ${props.className || ""}`}>
			<div className="sidebar-main">
				<button
					className="btn-close-sidebar btn-transparent"
					onClick={props.handleClose}
					type="button"
				>
					<FiX />
				</button>

				<div className="sidebar-header">
					<Link className="sidebar-logo-link" to="/">
						<img className="sidebar-logo" src={logo} alt="Logo Plain" />
						<img className="sidebar-iso" src={iso} alt="Logo Plain" />
					</Link>

					<button
						type="button"
						title="Menu"
						onClick={props.toggleNav}
						className="btn btn-transparent btn-menu"
					>
						<FiMenu />
					</button>
				</div>

				<ul className="menu-core">
					<li>
						<NavLink to="/" exact activeClassName="active">
							<OverlayTrigger
								placement="right"
								overlay={<Tooltip>Inicio</Tooltip>}
							>
								<FiHome />
							</OverlayTrigger>
							<span className="option-text">Inicio</span>
						</NavLink>
					</li>
					<li>
						<NavLink to="/calendar" exact activeClassName="active">
							<OverlayTrigger
								placement="right"
								overlay={<Tooltip>Calendario</Tooltip>}
							>
								<FiCalendar />
							</OverlayTrigger>
							<span className="option-text">
								Mi calendario
							</span>
						</NavLink>
					</li>
					<li>
						<NavLink to="/plans" activeClassName="active">
							<OverlayTrigger
								placement="right"
								overlay={<Tooltip>Planificación</Tooltip>}
							>
								<FiTrello />
							</OverlayTrigger>
							<span className="option-text">Planificación</span>
						</NavLink>
					</li>

					<li>
						<NavLink to="/my-times" activeClassName="active">
							<OverlayTrigger
								placement="right"
								overlay={<Tooltip>Fichajes</Tooltip>}
							>
								<FiClock />
							</OverlayTrigger>
							<span className="option-text">Fichajes</span>
						</NavLink>
						{((isAdmin() || isSupervisor()) && (props.context === "times" || props.className === "mobile-sidebar")) && (
							<ul className="menu-sub">
								<li>
									<NavLink to="/times" exact activeClassName="active">
										<span className="option-text">Supervisar fichajes</span>
									</NavLink>
								</li>
								<li>
									<NavLink to="/without-time" exact activeClassName="active">
										<span className="option-text">Fichajes no realizados</span>
									</NavLink>
								</li>
							</ul>
						)}
					</li>

					<li>
						<NavLink to="/absences" exact activeClassName="active">
							<OverlayTrigger
								placement="right"
								overlay={<Tooltip>Ausencias</Tooltip>}
							>
								<FiExternalLink />
							</OverlayTrigger>
							<span className="option-text">
								{isAdmin() || isSupervisor() ? "Ausencias" : "Mis ausencias"}
							</span>
						</NavLink>
						{((isAdmin() || isSupervisor()) && (props.context === "absences" || props.className === "mobile-sidebar")) && (
							<ul className="menu-sub">
								<li>
									<NavLink to="/pending-absences" exact activeClassName="active">
										<span className="option-text">Aprobar ausencias</span>
									</NavLink>
								</li>
								<li>
									<NavLink to="/managed-absences" exact activeClassName="active">
										<span className="option-text">Ausencias gestionadas</span>
									</NavLink>
								</li>
							</ul>
						)}
					</li>
					{(user.id === 6 || user.id === 3 || user.id === 1489) && (
						<li>
							<NavLink to="/documents" exact activeClassName="active">
								<OverlayTrigger
									placement="right"
									overlay={<Tooltip>Documentos</Tooltip>}
								>
									<FiFolder />
								</OverlayTrigger>
								<span className="option-text">
									{isAdmin() ? "Documentos" : "Mis documentos"}
								</span>
							</NavLink>
						</li>
					)}

				</ul>

				{isAdmin() && (
					<ul className="menu-secondary">
						<li>
							<NavLink to="/employees" exact activeClassName="active">
								<OverlayTrigger
									placement="right"
									overlay={<Tooltip>Empleados</Tooltip>}
								>
									<FiUser />
								</OverlayTrigger>
								<span className="option-text">Empleados</span>
							</NavLink>

							{(props.context === "employees" ||
								props.className === "mobile-sidebar") && (
									<ul className="menu-sub">
										<li>
											<NavLink
												to="/custom-fields"
												exact
												activeClassName="active"
											>
												<span className="option-text">Campos personalizados</span>
											</NavLink>
										</li>
										<li>
											<NavLink
												to="/employee-functions"
												exact
												activeClassName="active"
											>
												<span className="option-text">Funciones</span>
											</NavLink>
										</li>
										<li>
											<NavLink
												to="/employee-ubications"
												exact
												activeClassName="active"
											>
												<span className="option-text">Ubicaciones</span>
											</NavLink>
										</li>
									</ul>
								)}
						</li>
						<li>
							<NavLink to="/groups" exact activeClassName="active">
								<OverlayTrigger
									placement="right"
									overlay={<Tooltip>Grupos</Tooltip>}
								>
									<FiUsers />
								</OverlayTrigger>
								<span className="option-text">Grupos</span>
							</NavLink>

							{(props.context === "groups" ||
								props.className === "mobile-sidebar") && (
									<ul className="menu-sub">
										<li>
											<NavLink to="/staffing-levels" exact activeClassName="active">
												<span className="option-text">Necesidades personal</span>
											</NavLink>
										</li>
									</ul>
								)}
						</li>
						<li>
							<NavLink to="/shifts" exact activeClassName="active">
								<OverlayTrigger
									placement="right"
									overlay={<Tooltip>Turnos</Tooltip>}
								>
									<FiRefreshCcw />
								</OverlayTrigger>
								<span className="option-text">Turnos</span>
							</NavLink>

							{(props.context === "shifts" ||
								props.className === "mobile-sidebar") && (
									<ul className="menu-sub">
										<li className="hidden-xs hidden-sm">
											<NavLink to="/shift-patterns" exact activeClassName="active">
												<span className="option-text">Ruedas de turnos</span>
											</NavLink>
										</li>
										<li>
											<NavLink to="/time-types" exact activeClassName="active">
												<span className="option-text">Tipos de tiempo</span>
											</NavLink>
										</li>
									</ul>
								)}
						</li>
						<li>
							<NavLink to="/rules" exact activeClassName="active">
								<OverlayTrigger
									placement="right"
									overlay={<Tooltip>Reglas</Tooltip>}
								>
									<FiSliders />
								</OverlayTrigger>
								<span className="option-text">Reglas</span>
							</NavLink>
							{(props.context === "rules" ||
								props.className === "mobile-sidebar") && (
									<ul className="menu-sub">
										<li>
											<NavLink to="/accounts" exact activeClassName="active">
												<span className="option-text">Cuentas</span>
											</NavLink>
										</li>
										<li>
											<NavLink to="/contracts" exact activeClassName="active">
												<span className="option-text">Contratos</span>
											</NavLink>
										</li>
										<li>
											<NavLink to="/transactions" exact activeClassName="active">
												<span className="option-text">Transacciones</span>
											</NavLink>
										</li>
									</ul>
								)}
						</li>
						<li>
							<NavLink to="/calendars" exact activeClassName="active">
								<OverlayTrigger
									placement="right"
									overlay={<Tooltip>Calendarios</Tooltip>}
								>
									<FiCalendar />
								</OverlayTrigger>
								<span className="option-text">Calendarios</span>
							</NavLink>
							{(props.context === "calendars" ||
								props.className === "mobile-sidebar") && (
									<ul className="menu-sub">
										<li>
											<NavLink to="/day-types" exact activeClassName="active">
												<span className="option-text">Tipos de día</span>
											</NavLink>
										</li>
									</ul>
								)}
						</li>
					</ul>
				)}
			</div>
			<div className="sidebar-bottom">
				<a
					className="link-help"
					title="Academia de Plain"
					rel="noopener noreferrer"
					target="_blank"
					href="https://help.plain.ninja/es/"
				>

					<OverlayTrigger
						placement="right"
						overlay={<Tooltip>Ayuda</Tooltip>}
					>
						<FiHelpCircle className="icon-help" />
					</OverlayTrigger>
				</a>

				<OverlayTrigger overlay={popover} trigger="click">
					<div className="sidebar-menu-profile">
						<Avatar
							userId={user.id}
							className="sidebar-avatar avatar-xs"
							alt={user.name}
						/>
						<span className="sidebar-username">
							{user.name} {user.surname}

							<FiChevronRight className="sidebar-menu-arrow" />
						</span>
					</div>
				</OverlayTrigger>
			</div>

		</section>
	);
};

const mapStateToProps = (state) => {
	// console.log(state);
	return {
		user: state.authReducer.user,
	};
};

export default connect(mapStateToProps, null)(withRouter(Sidebar));

// export default Sidebar;

// <li>
// <NavLink to="/multigroups" exact activeClassName="active">
//   <span className="option-text">Multigrupos</span>
// </NavLink>
// </li>
